import React from 'react';
import './CounterArea.css'; // Import the CSS file for styling

const CounterArea = () => {
  return (
    <section className="counterArea">
      <div className="counter">
      <div className="container">
        <div className="row">
          <div className="col-sm-3 pb-4 pb-sm-0 text-center">
            <div className="counter-number">50565</div>
            <div className="counter-title">Happy Customers</div>
          </div>
          <div className="col-sm-3 pb-4 pb-sm-0 text-center">
            <div className="counter-number">3000</div>
            <div className="counter-title">Cars In Garage</div>
          </div>
          <div className="col-sm-3 pb-4 pb-sm-0 text-center">
            <div className="counter-number">100920</div>
            <div className="counter-title">Total Kilometer/k.m</div>
          </div>
          <div className="col-sm-3 pb-4 pb-sm-0 text-center">
            <div className="counter-number">100920</div>
            <div className="counter-title">Car Center Solution</div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

export default CounterArea;
