import React, { useState } from 'react';
import './SectionInf.css'; // Ensure this file includes your custom CSS
import '@fortawesome/fontawesome-free/css/all.min.css';
import ReactPlayer from 'react-player/youtube';

const SectionInf = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentVideoLink, setCurrentVideoLink] = useState('');

  const courses = [
    {
      id: 1,
      title: 'Introduction to Cryptocurrency and Blockchain Technology',
      description: 'Dive into the world of digital currencies with this comprehensive beginner course. Understand the basics of cryptocurrency, the underlying blockchain technology, and how these innovations are transforming the financial landscape. Perfect for those new to the crypto space. This course will provide you with the foundational knowledge to start your journey confidently.',
      instructor: 'John Doe',
      rating: 4.5,
      link: 'https://youtu.be/2yJqjTiwpxM'
    }
  ];

  const openModal = (link) => {
    setCurrentVideoLink(link);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentVideoLink('');
  };

  return (
    <section className="section-inf">
      <div className="container py-5">
        <div className="row align-items-center">
          {/* Text Content */}
          <div className="col-lg-6 info-data">
            <div className="info-text">
              <h6 className="subheading">Welcome to SaleeTours</h6>
              <h1 className="heading">Your Premier Vehicle Rental Partner</h1>
              <p className="description">
                With over a decade of expertise, SaleeTours is committed to offering an exceptional travel experience. Our diverse fleet includes the latest models, ensuring you find the perfect vehicle for any journey—whether it’s a business trip, family vacation, or weekend getaway.
              </p>
              <h2 className="why-choose-heading">Why Choose SaleeTours?</h2>
              <ul className="benefits-list">
                <div className="check-section">
                  <div className="check1">
                    <li><i className="fa-solid fa-check"></i> <strong>Decade of Excellence</strong></li>
                    <li><i className="fa-solid fa-check"></i> <strong>Affordable Rates</strong></li>
                    <li><i className="fa-solid fa-check"></i> <strong>Luxurious Comfort</strong></li>
                  </div>
                  <div className="check1">
                    <li><i className="fa-solid fa-check"></i> <strong>Professional Service</strong></li>
                    <li><i className="fa-solid fa-check"></i> <strong>Expert Guides</strong></li>
                    <li><i className="fa-solid fa-check"></i><strong>Easy Booking</strong></li>
                  </div>
                  <div className="check1">
                    <li><i className="fa-solid fa-check"></i><strong>Custom Tours:</strong></li>
                    <li><i className="fa-solid fa-check"></i><strong>24/7 Assistance</strong></li>
                    <li><i className="fa-solid fa-check"></i><strong>Exciting Drives</strong></li>
                  </div>
                </div>
              </ul>
            </div>
          </div>

          {/* Image and Video */}
          <div className="col-lg-6 image-view">
            <div className="image-container position-relative">
              <img
                src={require('../assets/img/7.png')} // Adjust the path as needed
                alt="SaleeTours"
                className="img-fluid"
              />
              <button
                type="button"
                className="video-btn position-absolute"
                onClick={() => openModal('https://youtu.be/8vPpJIqGMBI')}
              >
                <i className="bi bi-play-circle-fill"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button
              onClick={closeModal}
              className="close-button"
              aria-label="Close modal"
            >
              &times;
            </button>
            <ReactPlayer
              url={currentVideoLink}
              width="100%"
              height="400px"
              controls
              playing
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default SectionInf;
