// src/pages/LandingPage.js

import React from 'react';
import Navbar from '../components/Navbar';
import SwiperCarousel from '../components/SwiperCarousel';
import SectionInf from '../components/SectionInf';
import ReservationForm from '../components/ReservationForm';
import VehicelFeatures from '../components/VehicelFeatures';
import CounterArea from '../components/CounterArea';
import TestimonialArea from '../components/TestimonialArea';
import LetsTalkArea from '../components/LetsTalkArea';
import FooterArea from '../components/FooterArea';
const LandingPage = () => {
  return (
    <div>
      <Navbar />
      <SwiperCarousel />
      <SectionInf />
      <ReservationForm />
      <VehicelFeatures />
      <CounterArea />
      <TestimonialArea />
      <LetsTalkArea />
      <FooterArea />
      
    </div>
  );
}

export default LandingPage;
