import React, { useState, useRef } from 'react';
import { Box, TextField, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, CircularProgress, Snackbar,MenuItem  } from '@mui/material';
import { styled } from '@mui/system';
import { PhotoCamera, Email, Phone, WhatsApp, Instagram, Facebook, Person } from '@mui/icons-material';
import Webcam from 'react-webcam';
import { useNavigate } from 'react-router-dom';

const Input = styled('input')({
  display: 'none',
});

const Container = styled(Box)({
  maxWidth: '500px',
  margin: 'auto',
  padding: '2em',
  background: 'rgba(0, 0, 0, 0.8)',
  borderRadius: '20px',
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  color: '#fff',
  '@media (max-width: 600px)': {
    padding: '1em',
  },
});

const SubmitButton = styled(Button)({
  marginTop: '1em',
  borderRadius: '25px',
  background: '#333',
  color: '#fff',
  fontWeight: 'bold',
  padding: '10px 20px',
  '&:hover': {
    background: '#1976d2',
  },
});

const CommonButton = styled(Button)({
  marginTop: '1em',
  background: '#fff',
  color: '#fff',
  padding: '10px 20px',
  '&:hover': {
    background: '#1976d2',
  },
});

const ImagePreview = styled('img')({
  width: '100%',
  maxHeight: '200px',
  objectFit: 'cover',
  borderRadius: '10px',
  marginTop: '1em',
});

const TouristTypeRentForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
    phoneNumber: '',
    passport: null,
    sriLankaLicense: null,
    whatsapp: '',
    instagram: '',
    facebook: '',
    diverType: '', // Added for dropdown selection
  });
  const [errors, setErrors] = useState({});
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [currentInput, setCurrentInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false);
  const webcamRef = useRef(null);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!validTypes.includes(file.type)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Invalid file type. Please upload an image file.',
        }));
        setFormData((prevData) => ({
          ...prevData,
          [name]: null,
        }));
        return;
      }

      if (file.size > maxSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'File size exceeds 5MB.',
        }));
        setFormData((prevData) => ({
          ...prevData,
          [name]: null,
        }));
        return;
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));

      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    }
  };

  const handleOpenCamera = (inputName) => {
    setCurrentInput(inputName);
    setIsCameraOpen(true);
  };

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (currentInput) {
      const blob = dataURLtoBlob(imageSrc);
      const file = new File([blob], `${currentInput}.png`, { type: 'image/png' });
      setFormData((prevData) => ({
        ...prevData,
        [currentInput]: file,
      }));
    }
    setIsCameraOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoadingDialogOpen(true);

    const requiredFields = ['name', 'email', 'contactNumber', 'passport', 'sriLankaLicense', 'whatsapp', 'instagram', 'facebook', 'diverType'];
    const newErrors = {};

    for (const field of requiredFields) {
      if (!formData[field]) {
        newErrors[field] = 'This field is required';
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setLoading(false);
      setLoadingDialogOpen(false);
      return;
    }

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    try {
      const response = await fetch('https://saleetours.com/api/rents/tourist-rent', {
        method: 'POST',
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error('Failed to submit data');
      }

      const result = await response.json();
      console.log(result);
      setSuccessMessage('Tourist rent data saved successfully!');
      
      // Navigate to the next route after a delay
      setTimeout(() => {
        navigate('/thank-you'); // Replace with your actual next route
      }, 2000);



      setFormData({
        name: '',
        email: '',
        contactNumber: '',
        phoneNumber: '',
        passport: null,
        sriLankaLicense: null,
        whatsapp: '',
        instagram: '',
        facebook: '',
        diverType: '', // Reset dropdown selection
      });
      setErrors({});
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setLoading(false);
      setLoadingDialogOpen(false);
    }
  };

  const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  return (
    <Container>
      
      <form onSubmit={handleSubmit}>

        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: 1,
            input: { color: '#fff' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
              },
              '&:hover fieldset': {
                borderColor: '#fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff',
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person style={{ color: '#fff' }} />
              </InputAdornment>
            ),
            style: { color: '#fff' },
          }}
          InputLabelProps={{ style: { color: '#fff' } }}
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleInputChange}
          fullWidth
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: 1,
            input: { color: '#fff' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
              },
              '&:hover fieldset': {
                borderColor: '#fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff',
              },
            },
          }}
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email style={{ color: '#fff' }} />
              </InputAdornment>
            ),
            style: { color: '#fff' },
          }}
          InputLabelProps={{ style: { color: '#fff' } }}
        />
<TextField
  label="Diver or Without Diver"
  name="diverType"
  select
  value={formData.diverType || ''} // Ensure this is controlled
  onChange={handleInputChange}
  fullWidth
  margin="normal"
  required
  sx={{
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 1,
    input: { color: '#fff' },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.5)',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
      },
    },
    '& .MuiSelect-select': {
      color: '#fff', // Set the dropdown text color to white
    },
  }}
  InputLabelProps={{ style: { color: '#fff' } }}
>
  <MenuItem value="" disabled>Select</MenuItem> {/* Default option */}
  <MenuItem value="diver">Diver</MenuItem>
  <MenuItem value="without diver">Without Diver</MenuItem>
</TextField>


        <TextField
          label="Contact Number"
          name="contactNumber"
          value={formData.contactNumber}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: 1,
            input: { color: '#fff' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
              },
              '&:hover fieldset': {
                borderColor: '#fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff',
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Phone style={{ color: '#fff' }} />
              </InputAdornment>
            ),
            style: { color: '#fff' },
          }}
          InputLabelProps={{ style: { color: '#fff' } }}
        />
        
        <TextField
          label="WhatsApp"
          name="whatsapp"
          value={formData.whatsapp}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: 1,
            input: { color: '#fff' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
              },
              '&:hover fieldset': {
                borderColor: '#fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff',
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <WhatsApp style={{ color: '#fff' }} />
              </InputAdornment>
            ),
            style: { color: '#fff' },
          }}
          InputLabelProps={{ style: { color: '#fff' } }}
        />
        <TextField
          label="Instagram"
          name="instagram"
          value={formData.instagram}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: 1,
            input: { color: '#fff' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
              },
              '&:hover fieldset': {
                borderColor: '#fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff',
              },
            },
          }}
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Instagram style={{ color: '#fff' }} />
              </InputAdornment>
            ),
            style: { color: '#fff' },
          }}
          InputLabelProps={{ style: { color: '#fff' } }}
        />
        <TextField
          label="Facebook"
          name="facebook"
          value={formData.facebook}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: 1,
            input: { color: '#fff' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
              },
              '&:hover fieldset': {
                borderColor: '#fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff',
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Facebook style={{ color: '#fff' }} />
              </InputAdornment>
            ),
            style: { color: '#fff' },
          }}
          InputLabelProps={{ style: { color: '#fff' } }}
        />
        
    
        
        <label htmlFor="passport">
          <Input accept="image/*" id="passport" name="passport" type="file" onChange={handleFileChange} />
          <CommonButton variant="contained" component="span" sx={{ mt: 2, color: '#fff', backgroundColor: '#333' }} startIcon={<PhotoCamera />}>
            Upload Passport
          </CommonButton>
        </label>
        {errors.passport && <Typography color="error">{errors.passport}</Typography>}
        {formData.passport && <ImagePreview src={URL.createObjectURL(formData.passport)} alt="Passport Preview" />}
        
        <label htmlFor="sriLankaLicense">
          <Input accept="image/*" id="sriLankaLicense" name="sriLankaLicense" type="file" onChange={handleFileChange} />
          <CommonButton variant="contained" sx={{ mt: 2, color: '#fff', backgroundColor: '#333' }} component="span" startIcon={<PhotoCamera />}>
            Upload Sri Lanka License
          </CommonButton>
        </label>
        {errors.sriLankaLicense && <Typography color="error">{errors.sriLankaLicense}</Typography>}
        {formData.sriLankaLicense && <ImagePreview src={URL.createObjectURL(formData.sriLankaLicense)} alt="Sri Lanka License Preview" />}

        <SubmitButton variant="contained" type="submit" disabled={loading}>
          Submit
        </SubmitButton>
      </form>

      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        message={successMessage}
      />

      <Dialog open={loadingDialogOpen}>
        <DialogContent>
          <CircularProgress /> {/* Show loading spinner */}
          <Typography>Submitting...</Typography> {/* Loading text */}
        </DialogContent>
      </Dialog>

      <Dialog open={isCameraOpen} onClose={() => setIsCameraOpen(false)}>
        <DialogTitle>Take a Picture</DialogTitle>
        <DialogContent>
          <Webcam audio={false} ref={webcamRef} screenshotFormat="image/png" width="100%" />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCameraOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCapture} color="primary">
            Capture
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TouristTypeRentForm;
