import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import './SwiperCarousel.css';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const SwiperCarousel = () => {
  return (
    <AutoplaySlider
      animation="cubeAnimation"
      play={true}
      cancelOnInteraction={false} // should stop playing on user interaction
      interval={3000}
    >
      <div className="slider-slide">
        <div className="slide-content">
          <h1>Rental Vehicle</h1>
          <p>Bmw <span className="rent-price">$700</span> /Day</p>
          <button>Rent Now</button>
        </div>
        <img src={require('../assets/img/001.jpeg')} alt="Bmw" className="slide-image" />
      </div>

      <div className="slider-slide">
        <div className="slide-content">
          <h1>Rental Vehicle</h1>
          <p>Lexus <span className="rent-price">$600</span> /Day</p>
          <button>Rent Now</button>
        </div>
        <img src={require('../assets/img/02.png')} alt="Lexus" className="slide-image" />
      </div>

      <div className="slider-slide">
        <div className="slide-content">
          <h1>Rental Vehicle</h1>
          <p>Rolls Royce Cullinan <span className="rent-price">$900</span> /Day</p>
          <button>Rent Now</button>
        </div>
        <img src={require('../assets/img/03.png')} alt="Rolls Royce Cullinan" className="slide-image" />
      </div>
    </AutoplaySlider>
  );
};

export default SwiperCarousel;
