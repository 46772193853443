import React from 'react';
import './TestimonialArea.css'; // Import the CSS file for styling

const TestimonialArea = () => {
  return (
    <section className="testimonalArea">
        <div className="testimonialArea"> {/* Applied the class name here */}
      <div className="container py-5 my-0 my-lg-5" id="testimonial">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h6 className="tittle">Testimonial</h6>
            <h2 className="fw-bold pb-5">Words from Our Satisfied Clients</h2>
          </div>
        </div>

        <div className="row g-4">
   
          <div className="col-lg-3 col-sm-6">
            <div className="testimonial-card mb-3 mb-lg-0">
              <div className="testimonial-img">
                <img src={require('../assets/img/testimonial-img.png')} alt="Jessica" className="img-fluid" />
              </div>
              <h5 className="fw-bold">Jessica</h5>
              <p>CEO, XYZ Company</p>
              <div className="testimonial-icon">
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
              </div>
              <p>
                SwiftStrategix exceeded our expectations in every way. Their attention to detail and strategic approach truly set them apart.
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="testimonial-card mb-3 mb-lg-0">
              <div className="testimonial-img">
                <img src={require('../assets/img/testimonial-img2.png')}alt="John Doe" className="img-fluid" />
              </div>
              <h5 className="fw-bold">John Doe</h5>
              <p>Business Consultant</p>
              <div className="testimonial-icon">
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star"></i>
              </div>
              <p>
                SaleeTours provided me with an exceptional rental experience. From the moment I made my reservation online to returning the vehicle.
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="testimonial-card mb-3 mb-lg-0">
              <div className="testimonial-img">
                <img src={require('../assets/img/testimonial-img3.png')} alt="Sarah Johnson" className="img-fluid" />
              </div>
              <h5 className="fw-bold">Sarah Johnson</h5>
              <p>Customer</p>
              <div className="testimonial-icon">
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star"></i>
                <i className="bi bi-star"></i>
              </div>
              <p>
                I've rented cars from various companies, but SaleeTours truly stands out. Not only did they offer competitive prices.
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="testimonial-card mb-3 mb-lg-0">
              <div className="testimonial-img">
                <img src={require('../assets/img/testimonial-img4.png')}  alt="Jane Smith" className="img-fluid" />
              </div>
              <h5 className="fw-bold">Jane Smith</h5>
              <p>Founder, 123 Solutions</p>
              <div className="testimonial-icon">
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
              </div>
              <p>
                I recently rented a car from SaleeTours for a business trip, and I couldn't be happier with the service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    
  );
};

export default TestimonialArea;
