import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ReservationForm.css';
import { MenuItem, Select, FormControl, InputLabel, TextField, Autocomplete } from '@mui/material';
import maplibregl, { Popup } from 'maplibre-gl'; // Import Popup
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const ReservationForm = () => {
  const pickupRef = useRef(null);
  const dropoffRef = useRef(null);
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [availableVehicles, setAvailableVehicles] = useState([]);
  const navigate = useNavigate();
  const [vehicleType, setVehicleType] = useState('');
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [pickupOptions, setPickupOptions] = useState([]);
  const [dropoffOptions, setDropoffOptions] = useState([]);
  const [pickupValue, setPickupValue] = useState('');
  const [dropoffValue, setDropoffValue] = useState('');
  const [pickupDate, setPickupDate] = useState(null);
  const [dropoffDate, setDropoffDate] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [distance, setDistance] = useState(null);
  const [additionalCost, setAdditionalCost] = useState(0);

  // Fetch vehicle types
  useEffect(() => {
    const fetchVehicleTypes = async () => {
      try {
        const response = await fetch('https://saleetours.com/api/vehicles/types');
        const types = await response.json();
        setVehicleTypes(types);
      } catch (error) {
        console.error('Error fetching vehicle types:', error);
      }
    };
    fetchVehicleTypes();
  }, []);

  // Load the map
  const loadMap = () => {
    const newMap = new maplibregl.Map({
      container: mapRef.current,
      style: 'https://maps.geoapify.com/v1/styles/osm-bright/style.json?apiKey=6eb9aceee8eb4bb98394a81345a63a82',
      center: [80.215002, 6.032758],
      zoom: 13,
    });
    newMap.on('load', () => {
      setMap(newMap);
    });
  };

  useEffect(() => {
    loadMap();
  }, []);

  const handleAutocomplete = async (event, value, setOptions) => {
    if (value.length > 0) {
      try {
        const response = await fetch(`https://api.geoapify.com/v1/geocode/autocomplete?text=${value}&apiKey=6eb9aceee8eb4bb98394a81345a63a82`);
        const data = await response.json();
        const options = data.features.map(feature => feature.properties.formatted);
        setOptions(options);
      } catch (error) {
        console.error('Error fetching autocomplete suggestions:', error);
      }
    } else {
      setOptions([]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setResponseMessage('');
    console.log('Form submitted');
    console.log('Pickup Location:', pickupValue);
    console.log('Dropoff Location:', dropoffValue);
    console.log('Pickup Date:', pickupDate);
    console.log('Dropoff Date:', dropoffDate);
  
    const pickupLocation = pickupValue;
    const dropoffLocation = dropoffValue;
  
    try {
      const pickupResponse = await fetch(`https://api.geoapify.com/v1/geocode/search?text=${pickupLocation}&apiKey=6eb9aceee8eb4bb98394a81345a63a82`);
      const pickupData = await pickupResponse.json();
  
      if (!pickupData.features || pickupData.features.length === 0) {
        throw new Error('Pickup location not found');
      }
  
      const pickupCoords = pickupData.features[0].geometry.coordinates;
  
      const dropoffResponse = await fetch(`https://api.geoapify.com/v1/geocode/search?text=${dropoffLocation}&apiKey=6eb9aceee8eb4bb98394a81345a63a82`);
      const dropoffData = await dropoffResponse.json();
  
      if (!dropoffData.features || dropoffData.features.length === 0) {
        throw new Error('Dropoff location not found');
      }
  
      const dropoffCoords = dropoffData.features[0].geometry.coordinates;
  
      const routeResponse = await fetch('https://api.geoapify.com/v1/routematrix?apiKey=6eb9aceee8eb4bb98394a81345a63a82', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          mode: 'drive',
          sources: [{ location: pickupCoords }],
          targets: [{ location: dropoffCoords }],
        }),
      });
  
      const routeData = await routeResponse.json();
      console.log('Route Matrix Response:', routeData);
  
      if (routeData.sources_to_targets.length > 0) {
        const route = routeData.sources_to_targets[0][0];
        const distanceMeters = route.distance;
        const distanceKilometers = (distanceMeters / 1000) ; // Convert to kilometers
  
        // Cost calculation
        const freeKilometers = 200;
        const costPerKilometer = 5;
        const inlpay = 4000;
  
        let totalCost = 0;
        if (distanceKilometers* 2 > freeKilometers) {
          const additionalKilometers = distanceKilometers* 2 - freeKilometers;
          totalCost = inlpay + (additionalKilometers * costPerKilometer);
        } else {
          totalCost = inlpay;
        }
  
        console.log(totalCost);
        setAdditionalCost(totalCost); // Store the additional cost
  
        if (map) {
          const routeCoordinates = [pickupCoords, dropoffCoords];
  
          // Remove existing layers and sources if they exist
          const layersToRemove = ['route', 'pickup-marker', 'dropoff-marker'];
          layersToRemove.forEach((id) => {
            if (map.getLayer(id)) {
              map.removeLayer(id);
            }
            if (map.getSource(id)) {
              map.removeSource(id);
            }
          });
  
          map.addSource('route', {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'line', // Added this line to specify the geometry type
                coordinates: routeCoordinates,
              },
            },
          });
  
          map.addLayer({
            id: 'route',
            type: 'line',
            source: 'route',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#ff0000',
              'line-width': 5,
            },
          });
  
          const createMarkerElement = (title, color) => {
            const el = document.createElement('div');
            el.className = 'marker';
            el.style.backgroundImage = `url(https://api.geoapify.com/v1/icon/?type=awesome&color=${color}&icon=car&iconType=awesome&apiKey=6eb9aceee8eb4bb98394a81345a63a82)`;
            el.style.width = '37px';
            el.style.height = '56px';
            el.style.backgroundSize = 'contain';
            el.title = title;
            return el;
          };
  
          new maplibregl.Marker({ element: createMarkerElement('Pickup Location', 'red') })
            .setLngLat(pickupCoords)
            .addTo(map);
  
          new maplibregl.Marker({ element: createMarkerElement('Dropoff Location', 'blue') })
            .setLngLat(dropoffCoords)
            .addTo(map);
  
          const bounds = new maplibregl.LngLatBounds();
          routeCoordinates.forEach(coord => bounds.extend(coord));
          map.fitBounds(bounds, { padding: 20 });
        }
  
        // Display the full-screen overlay
        const overlay = document.createElement('div');
        overlay.id = 'popup-overlay';
        overlay.innerHTML = `
          <div class="popup-content">
            <button id="close-popup" style="position: absolute; top: 10px; right: 10px; background: transparent; border: none; cursor: pointer;">
              <img src="https://img.icons8.com/material-outlined/24/000000/close.png" alt="Close" />
            </button>
            <h4>Reservation Confirmed</h4>
            <p>Distance: ${distanceKilometers.toFixed(2)} km</p>
            <p>Rent Now for Rs ${totalCost.toFixed(2)}</p>
            <button id="popup-button" style="background-color: transparent; border: none; color: #007bff; cursor: pointer; text-decoration: underline;">Rent Now</button>
          </div>
        `;
        document.body.appendChild(overlay);
  
        // Close the overlay when clicking the close button or the Rent Now button
        document.getElementById('close-popup').addEventListener('click', () => {

          document.body.removeChild(overlay);
        });
        document.getElementById('popup-button').addEventListener('click', () => {
          document.body.removeChild(overlay);
          navigate('/vehicles'); 
        });
  
        setResponseMessage('Reservation successful!');
        setDistance(distanceMeters / 1000); // Convert to kilometers
      } else {
        throw new Error('Route not found');
      }
    } catch (error) {
      console.error('Error fetching route matrix:', error);
      setResponseMessage(error.message);
    }
  };
  
    

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <section className="reservationForm">
        <div className="bg-one">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h6 className="title">Rent Now</h6>
                <h1 className="fw-bold">Rental Car Reservation</h1>
                <form onSubmit={handleSubmit}>
                  <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center">
                    <div className="d-block d-sm-flex rent-option mt-5 tool-box">
                      <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
                        <InputLabel sx={{ color: '#ffffff' }}>Vehicle Type</InputLabel>
                        <Select
                          value={vehicleType}
                          onChange={(e) => setVehicleType(e.target.value)}
                          label="Vehicle Type"
                          sx={{
                            color: '#ffffff',
                            '.MuiOutlinedInput-notchedOutline': {
                              borderColor: '#ffffff',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#00f',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#f00',
                            },
                            '.MuiSvgIcon-root': {
                              color: '#ffffff',
                            },
                          }}>
                          <MenuItem value="">
                            <em>All</em>
                          </MenuItem>
                          {vehicleTypes.map((type) => (
                            <MenuItem key={type} value={type}>{type}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Autocomplete
                        options={pickupOptions}
                        value={pickupValue}
                        onChange={(event, newValue) => setPickupValue(newValue)}
                        onInputChange={(event, newInputValue) => {
                          setPickupValue(newInputValue);
                          handleAutocomplete(event, newInputValue, setPickupOptions);
                        }}
                        renderInput={(params) => <TextField {...params} label="Pick Up Location" />}
                        sx={{
                          width: '100%', // Make it full width
                          mb: 2,
                          '& .MuiInputLabel-root': {
                            color: '#ffffff',
                          },
                          '& .MuiInputBase-input': {
                            color: '#ffffff',
                            padding: '12px 14px',
                          },
                          '& .MuiInputBase-input::placeholder': {
                            color: '#ff0000',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ffffff',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#00f',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#00f',
                          },
                          '.MuiSvgIcon-root': {
                            color: '#ffffff',
                          },
                        }}
                      />

                      <DatePicker
                        label="Pick Up Date"
                        value={pickupDate}
                        onChange={(newValue) => setPickupDate(newValue)}
                        renderInput={(params) => <TextField {...params} aria-label="Pick Up Date" />}
                        sx={{
                          width: '100%',
                          mb: 2,
                          '& .MuiInputLabel-root': {
                            color: '#ffffff',
                          },
                          '& .MuiInputBase-input': {
                            color: '#ffffff',
                            padding: '12px 14px',
                          },
                          '& .MuiInputBase-input::placeholder': {
                            color: '#ff0000',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ffffff',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#00f',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#00f',
                          },
                          '.MuiSvgIcon-root': {
                            color: '#ffffff',
                          },
                          '& .MuiInputBase-root': {
                            height: '56px', // Adjust this value to increase height
                          },
                        }}
                      />

                      <Autocomplete
                        options={dropoffOptions}
                        value={dropoffValue}
                        onChange={(event, newValue) => setDropoffValue(newValue)}
                        onInputChange={(event, newInputValue) => {
                          setDropoffValue(newInputValue);
                          handleAutocomplete(event, newInputValue, setDropoffOptions);
                        }}
                        renderInput={(params) => <TextField {...params} label="Drop Off Location" />}
                        sx={{
                          width: '100%',
                          mb: 2,
                          '& .MuiInputLabel-root': {
                            color: '#ffffff',
                          },
                          '& .MuiInputBase-input': {
                            color: '#ffffff',
                            padding: '12px 14px',
                          },
                          '& .MuiInputBase-input::placeholder': {
                            color: '#ff0000',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ffffff',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#00f',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#00f',
                          },
                          '.MuiSvgIcon-root': {
                            color: '#ffffff',
                          },
                        }}
                      />

                      <DatePicker
                        label="Drop Off Date"
                        value={dropoffDate}
                        onChange={(newValue) => setDropoffDate(newValue)}
                        renderInput={(params) => <TextField {...params} aria-label="Drop Off Date" />}
                        sx={{
                          width: '100%',
                          mb: 2,
                          '& .MuiInputLabel-root': {
                            color: '#ffffff',
                          },
                          '& .MuiInputBase-input': {
                            color: '#ffffff',
                            padding: '12px 14px',
                          },
                          '& .MuiInputBase-input::placeholder': {
                            color: '#ff0000',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ffffff',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#00f',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#00f',
                          },
                          '.MuiSvgIcon-root': {
                            color: '#ffffff',
                          },'& .MuiInputBase-root': {
                            height: '56px', // Adjust this value to increase height
                          },
                        }}
                      />
                      <button type="submit" className="btn btn-primary" style={{ width: '100%', height: '50px',  marginBottom:'10px' }}>Reserve</button>
                    </div>
                  </div>
                </form>
                {distance && <div className="distance-message">Distance: {distance.toFixed(2)} km</div>}
                {additionalCost > 0 && <div className="cost-message">Rent Cost : Rs {additionalCost.toFixed(2)}</div>}
                <div ref={mapRef} style={{ width: '100%', height: '400px', marginTop: '20px' }} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </LocalizationProvider>
  );
};

export default ReservationForm;
