import './VehicelFeatures.css'; // Ensure this file exists in the correct directory
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const VehicelFeatures = () => {
  const [vehicleData, setVehicleData] = React.useState([]); // State to hold fetched vehicle data
  const [loading, setLoading] = React.useState(true); // State to manage loading state
  const [error, setError] = React.useState(null); // State to manage error

  const navigate = useNavigate(); // Initialize useNavigate
  
  // Fetch vehicle data from the API
  React.useEffect(() => {
    const fetchVehicleData = async () => {
      try {
        const response = await fetch('https://saleetours.com/api/vehicles'); // Adjust the endpoint to your API
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setVehicleData(data); // Set the fetched data to state
      } catch (error) {
        setError(error.message); // Set the error message
      } finally {
        setLoading(false); // Set loading to false once the fetch is complete
      }
    };

    fetchVehicleData();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading message
  }

  if (error) {
    return <div>Error: {error}</div>; // Show error message
  }

  // Slice the first 6 vehicles to display
  const displayedVehicles = vehicleData.slice(0, 6);

  // Handle navigation to the details page
  const handleSelect = (model) => {
    navigate(`/details/${model}`); // Navigate to the details page with the vehicle model
  };

  return (
    <section className='Feature'>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h6 className="title">SELECT YOUR VEHICLE</h6>
            <h1 className="pb-5 fw-bold">Emphasis on Luxury</h1>
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            {displayedVehicles.map((vehicle) => (
              <Card key={vehicle._id} sx={{ maxWidth: 345, margin: '10px' }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={vehicle.images[0]} // Use the first image from the array
                    alt={vehicle.model}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {vehicle.model} {/* Display model instead of title */}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {vehicle.description || 'No description available.'} {/* Handle description */}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" onClick={() => handleSelect(vehicle.model)}>
                    Select
                  </Button>
                </CardActions>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VehicelFeatures; // Corrected the component name
