
import React from 'react';
import Navbar from '../components/Navbar';
import ReservationForm from '../components/ReservationForm';
import CounterArea from '../components/CounterArea';
import TestimonialArea from '../components/TestimonialArea';
import LetsTalkArea from '../components/LetsTalkArea';
import FooterArea from '../components/FooterArea';
const RentPage = () => {
  return (
    <div>
      <Navbar />
      <ReservationForm />
      <CounterArea />
      <TestimonialArea />
      <LetsTalkArea />
      <FooterArea />

    </div>
  );
}

export default RentPage;
