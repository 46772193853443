import React, { useEffect, useState } from 'react';
import './VehiclesDetails.css';
import { useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FooterArea from '../components/FooterArea';
import NationalitySelection from '../components/NationalitySelection'; // Import your component
import Dialog from '@mui/material/Dialog'; // Import Dialog
import DialogTitle from '@mui/material/DialogTitle'; // Import DialogTitle
import DialogContent from '@mui/material/DialogContent'; // Import DialogContent
import DialogActions from '@mui/material/DialogActions'; // Import DialogActions
import Button from '@mui/material/Button'; // Import Button
import { Info as InfoIcon, Close as CloseIcon } from '@mui/icons-material'; // Importing Info and Close icons
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CheckIcon from '@mui/icons-material/Check';

const VehiclesDetails = () => {
    const { model } = useParams();
    const [vehicle, setVehicle] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [open, setOpen] = useState(false); // State to control the dialog

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        const fetchVehicleDetails = async () => {
            try {
                const response = await fetch(`https://saleetours.com/api/vehicles/${model}`);
                const data = await response.json();
                setVehicle(data);
            } catch (error) {
                console.error('Error fetching vehicle details:', error);
            }
        };

        fetchVehicleDetails();
    }, [model]);

    if (!vehicle) {
        return <div>Loading...</div>;
    }

    // Access vehicle properties directly
    const { vehicleProperty: property } = vehicle;

    const handleClose = () => {
        setOpen(false); // Close the dialog
    };

    return (
        <div className="car-details">
            <Navbar />
            <div className="car-details-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h1 className="fw-bold display-5">{vehicle.model} Details</h1>
                            <div className="d-flex justify-content-center">
                                <a className="pe-1" href="/">Home</a>
                                <p>| {vehicle.model} Details</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-4 my-5">
                <div className="row">
                <div className="col-lg-8">
    <h4 className="fw-bold text-start">General Information</h4>
    <p className="text-start">
        {vehicle.description || 'No description available.'}
    </p>
    <List sx={{ width: '100%', bgcolor: 'transparent', padding: 0 }}>
      <ListItem sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <ListItemAvatar>
          <Avatar sx={{ backgroundColor: 'transparent', border: '1px solid white' }}>
            <CheckIcon sx={{ color: 'white' }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={vehicle.location || 'Location not specified.'}
          sx={{
            color: 'white',
            '& .MuiListItemText-primary': {
              fontWeight: 'normal',
            },
          }}
        />
      </ListItem>
      <ListItem sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <ListItemAvatar>
          <Avatar sx={{ backgroundColor: 'transparent', border: '1px solid white' }}>
            <CheckIcon sx={{ color: 'white' }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={vehicle.cancellationPolicy || 'Cancellation policy not specified.'}
          sx={{
            color: 'white',
            '& .MuiListItemText-primary': {
              fontWeight: 'normal',
            },
          }}
        />
      </ListItem>
      <ListItem sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <ListItemAvatar>
          <Avatar sx={{ backgroundColor: 'transparent', border: '1px solid white' }}>
            <CheckIcon sx={{ color: 'white' }} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Book Now Pay Upon Arrival"
          sx={{
            color: 'white',
            '& .MuiListItemText-primary': {
              fontWeight: 'normal',
            },
          }}
        />
      </ListItem>
    </List>
    <h4 className="fw-bold pb-3 text-start">Image Gallery</h4>
    <div className="row">
        {vehicle.images.map((img, index) => (
            <div key={index} className="col-lg-6 mb-4 mb-lg-0 col-sm-6">
                <div className="img-box">
                    <a href="#">
                        <img src={img} alt="" className="img-fluid" />
                    </a>
                </div>
            </div>
        ))}
    </div>
    <h4 className="fw-bold pt-5 text-start">Rental Conditions</h4>
    <div className="accordion accordion-flush pt-3" id="accordionFlushExample">
        {[
            {
                id: 'flush-collapseOne',
                header: '1. Agreement and Exhibits',
                body: 'Explore the agreement and its exhibits to gain insights into our terms and commitments.'
            },
            {
                id: 'flush-collapseTwo',
                header: '2. Driving License and Age',
                body: 'The minimum age to rent a car varies depending on the car rental company and location.'
            },
            {
                id: 'flush-collapseThree',
                header: '3. Prices',
                body: 'Enter your travel dates and car preferences to see our latest rates.'
            },
            {
                id: 'flush-collapseFour',
                header: '4. Payments',
                body: 'We accept all major credit cards for a smooth and secure rental experience.'
            },
            {
                id: 'flush-collapseFive',
                header: '5. Delivery',
                body: 'Our delivery service ensures your items reach you promptly and securely.'
            },
            {
                id: 'flush-collapseSix',
                header: '6. Traffic Fines',
                body: 'Please be aware that traffic fines incurred during the rental period are the responsibility of the renter.'
            }
        ].map((item, index) => (
            <Accordion key={index} expanded={expanded === `panel${index + 1}`} onChange={handleChange(`panel${index + 1}`)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${item.id}-content`}
                    id={`${item.id}-header`}
                >
                    <Typography>{item.header}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>{item.body}</Typography>
                </AccordionDetails>
            </Accordion>
        ))}
    </div>
</div>

                    
                    <div className="col-lg-4 col-md-6 col-sm-12 ps-3 ps-lg-5">
                        <div className="car-menu">
                            <p className="rent-prices">
                                <span className="h3 fw-bold">${vehicle.price}/</span>rent per day
                            </p>
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                                <span className="car-icons d-flex align-items-center">
                                    <i className="fa-solid fa-door-open me-2"></i>Doors
                                </span>
                                <p className="text-end mb-0 menu-numbers">{property.doors || 'N/A'}</p>
                            </div>
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                                <span className="car-icons d-flex align-items-center">
                                    <i className="fa-solid fa-person me-2"></i>Passengers
                                </span>
                                <p className="text-end mb-0 menu-numbers">{property.passengers || 'N/A'}</p>
                            </div>
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                                <span className="car-icons d-flex align-items-center">
                                    <i className="fa-solid fa-transgender me-2"></i>Transmission
                                </span>
                                <p className="text-end mb-0 menu-numbers">{property.transmission || 'N/A'}</p>
                            </div>
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                                <span className="car-icons d-flex align-items-center">
                                    <i className="fa-solid fa-suitcase-rolling me-2"></i>Luggage
                                </span>
                                <p className="text-end mb-0 menu-numbers">{property.luggage || 'N/A'}</p>
                            </div>
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                                <span className="car-icons d-flex align-items-center">
                                    <i className="fa-solid fa-user me-2"></i>Age
                                </span>
                                <p className="text-end mb-0 menu-numbers">{property.minAge || 'N/A'}</p>
                            </div>
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                                <span className="car-icons d-flex align-items-center">
                                    <i className="fa-solid fa-temperature-arrow-up me-2"></i>Air Condition
                                </span>
                                <p className="text-end mb-0 menu-numbers">{property.airCondition ? 'Yes' : 'No'}</p>
                            </div>
                            <div className="d-flex flex-column flex-md-row lets-btn pt-3 menu-btn">
                                <div className="button-design ps-2 mb-2 mb-md-0">
                                    <button onClick={() => setOpen(true)}>Rent Now</button> {/* Open dialog on button click */}
                                </div>
                                <div className="button-design ps-2">
                                    <button>WhatsApp</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

     {/* Dialog for Nationality Selection */}
<Dialog 
    open={open} 
    onClose={handleClose} 
    maxWidth="sm" 
    fullWidth 
    PaperProps={{
        sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // Transparent black background
            backdropFilter: 'blur(10px)', // Optional: background blur effect
            borderRadius: '16px', // Rounded corners
            boxShadow: '0 6px 20px rgba(0, 0, 0, 0.3)', // Shadow for depth
        }
    }}
>
    <DialogTitle 
        sx={{ 
            color: '#fff', // White text for contrast
            textAlign: 'center',  
            fontSize: '1.5rem', // Larger font size for title
            fontWeight: '600', // Semi-bold title
            padding: '16px', // Padding around title
            borderBottom: '2px solid rgba(255, 255, 255, 0.1)', // Subtle bottom border
            display: 'flex', // Flexbox for icon and text alignment
            alignItems: 'center', // Center items vertically
            justifyContent: 'center' // Center items horizontally
        }}
    >
        <InfoIcon sx={{ marginRight: 1, fontSize: '2rem', color: '#FFD700' }} /> {/* Icon with styling */}
        SaleeTours vehicle rent 
        <CloseIcon 
            onClick={handleClose} 
            sx={{ 
                marginLeft: 'auto', // Push to the right
                cursor: 'pointer', // Cursor pointer on hover
                color: '#fff' // White color for the icon
            }} 
        />
    </DialogTitle>
    <DialogContent sx={{ backgroundColor: 'transparent', padding: 3 }}>
        <NationalitySelection />
    </DialogContent>
  
</Dialog>


            <FooterArea />
        </div>
    );
};

export default VehiclesDetails;
