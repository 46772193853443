import React, { useState } from 'react';
import { Box, Button, Typography, Snackbar, Alert } from '@mui/material';
import TouristTypeRentForm from './TouristTypeRentForm';
import LocalTypeRentForm from './LocalTypeRentForm';

import './NationalitySelection.css';

const NationalitySelection = () => {
  const [nationality, setNationality] = useState('');
  const [formType, setFormType] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleNationalitySelect = (type) => {
    setNationality(type);
    setFormType(type === 'local' ? 'local' : 'tourist');
    setSnackbarMessage(`You have selected ${type.charAt(0).toUpperCase() + type.slice(1)} nationality.`);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      className="nationality-selection" // Apply your styles from CSS
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        maxWidth: 400,
        margin: 'auto',
        mt: 5,
        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Semi-transparent black background
        borderRadius: '8px', // Rounded corners
        padding: 3, // Padding for inner spacing
        backdropFilter: 'blur(8px)', // Optional: adds a blur effect
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)', // Shadow for depth
      }}
    >
      {!formType ? (
        <>
          <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#FFFFFF' }}>
            What is your nationality?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleNationalitySelect('local')}
            sx={{ width: '100%' }} // Make buttons full width
          >
            Local
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleNationalitySelect('tourist')}
            sx={{ width: '100%' }} // Make buttons full width
          >
            Tourist
          </Button>
        </>
      ) : (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Typography variant="h6" component="h3" gutterBottom sx={{ color: '#FFFFFF' }}>
          The section you are in :{nationality.charAt(0).toUpperCase() + nationality.slice(1)}
          </Typography>
          {formType === 'local' ? <LocalTypeRentForm /> : <TouristTypeRentForm />}
        </Box>
      )}

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity="info"
          sx={{
            width: '100%',
            backgroundColor: '#2196F3', // Custom background color
            color: '#FFFFFF', // Custom text color
            borderRadius: 1, // Rounded corners
            boxShadow: 2, // Shadow for depth
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NationalitySelection;
