import React from 'react';
import './LetsTalkArea.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';

const LetsTalkArea = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    // Navigate to the desired route
    navigate('/contact'); // Change '/target-route' to your actual route
  };
  return (
    <>
    <section className="lestsTalkArea">
    <div className="talk-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h6 className="title">RENT YOUR CAR</h6>
              <h1 className="fw-bold">Curious about Renting?</h1>
              <div className="d-flex lets-btn pt-3">
                <div className="button-design ps-2">
                  <div className="btn-3"><button type="button"  onClick={handleButtonClick}>  Contact Us</button></div>
                </div>
                <div className="button-design ps-2">
                  <div className="btn-3"><button type="button">WhatsApp</button></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5 my-5">
        <div className="contact-area mx-0 mx-lg-5">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="d-flex contact mb-2 mb-sm-50">
                <i className="fa-solid fa-phone-volume"></i>
                <div className="div ps-3">
                  <p className="mb-0 fw-bold h5">Call us</p>
                  <p className="con-num">+458-2563-5896</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="d-flex contact">
                <i className="fa-solid fa-envelope-open-text"></i>
                <div className="div ps-3">
                  <p className="mb-2 fw-bold h5">Contact our team</p>
                  <p className="con-num">info@saleetours.com</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex contact">
                <i className="fa-solid fa-location-dot"></i>
                <div className="div ps-3">
                  <p className="mb-2 fw-bold h5">Location</p>
                  <p className="con-num">Mathara , Sri Lanka</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      
    </>
  );
};

export default LetsTalkArea;
