import React from 'react';
import { Typography, Button } from '@mui/material';
import Navbar from '../components/Navbar';
import CounterArea from '../components/CounterArea';
import TestimonialArea from '../components/TestimonialArea';
import LetsTalkArea from '../components/LetsTalkArea';
import FooterArea from '../components/FooterArea';

const ThankYouPage = () => {
  return (
    <div className='bg-contern'>
      <Navbar />
      <div className="about-banner text-center">
        <div className="container">
          <h1 className="fw-bold display-5">Thank You!</h1>
          <Typography variant="body1" style={{ marginTop: '10px' }}>
            Someone from our company will contact you after checking your information.
          </Typography>
          <div className="d-flex justify-content-center">
            <a className="pe-1" href="/">Home</a>
            <p>| Thank You</p>
          </div>
        </div>
      </div>
      <LetsTalkArea />
      <TestimonialArea />
      <FooterArea />
    </div>
  );
};

export default ThankYouPage;
