import React from 'react';
import './LoadingSpinner.css'; // Create this file for styling

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <img src={require('../assets/img/loading.gif')} alt="Loading..." />
    </div>
  );
}

export default LoadingSpinner;
