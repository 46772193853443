import './VehicelFeatures.css'; // Import the CSS file for styling
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const VehicelFeatures = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [vehicles, setVehicles] = useState([]); // State for fetched vehicles
  const [vehicleTypes, setVehicleTypes] = useState([]); // State for vehicle types
  const [loading, setLoading] = useState(true); // State for loading
  const navigate = useNavigate(); // Initialize useNavigate

  // Fetch vehicles from the API
  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await fetch('https://saleetours.com/api/vehicles'); // Adjust the endpoint to your API
        const data = await response.json();
        setVehicles(data);
      } catch (error) {
        console.error('Error fetching vehicles:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchVehicles();
  }, []);

  // Fetch vehicle types from the API
  useEffect(() => {
    const fetchVehicleTypes = async () => {
      try {
        const response = await fetch('https://saleetours.com/api/vehicles/types'); // Adjust the endpoint to your API
        const types = await response.json();
        setVehicleTypes(types); // Set vehicle types in state
      } catch (error) {
        console.error('Error fetching vehicle types:', error);
      }
    };

    fetchVehicleTypes();
  }, []);

  // Filter the vehicle data based on the search term and vehicle type
  const filteredVehicles = vehicles.filter((vehicle) =>
    vehicle.model.toLowerCase().includes(searchTerm.toLowerCase()) &&
    (vehicleType === '' || vehicle.type === vehicleType)
  );

  // Handle navigation to the details page
  const handleSelect = (model) => {
    navigate(`/details/${model}`); // Navigate to the details page with the vehicle model
  };

  return (
    <section className='Feature'>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h6 className="title">SELECT YOUR VEHICLE</h6>
            <h1 className="pb-5 fw-bold">Emphasis on Luxury</h1>
          </div>
          <div className="col-lg-12 text-center pb-4">
            <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
              <InputLabel sx={{ color: '#ffffff' }}>Vehicle Type</InputLabel>
              <Select
                value={vehicleType}
                onChange={(e) => setVehicleType(e.target.value)}
                label="Vehicle Type"
                sx={{
                  color: '#ffffff',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#ffffff',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#00f',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#f00',
                  },
                  '.MuiSvgIcon-root': {
                    color: '#ffffff',
                  },
                }}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {vehicleTypes.map((type) => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Search Vehicles"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              sx={{
                mb: 2,
                input: { color: '#ffffff' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#ffffff',
                  },
                  '&:hover fieldset': {
                    borderColor: '#f00',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00f',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#ffffff',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#00f',
                },
              }}
            />
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            {loading ? (
              <Typography variant="h6" color="text.secondary">
                Loading vehicles...
              </Typography>
            ) : (
              filteredVehicles.map((vehicle) => (
                <Card key={vehicle.model} sx={{ maxWidth: 345, m: 2 }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image={vehicle.images[0]} // Use the image URL from the API
                      alt={vehicle.model}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {vehicle.model}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Location: {vehicle.location} {/* Show location */}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Available: {vehicle.available ? 'Yes' : 'No'} {/* Show availability */}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary" onClick={() => handleSelect(vehicle.model)}>
                      Select
                    </Button>
                  </CardActions>
                </Card>
              ))
            )}
            {filteredVehicles.length === 0 && !loading && (
              <Typography variant="h6" color="text.secondary">
                No vehicles found.
              </Typography>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VehicelFeatures;
