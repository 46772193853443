import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './ContactPage.css';
import FooterArea from '../components/FooterArea';
import Navbar from '../components/Navbar';
const ContactPage = () => {
    return (
        <div className='bg-contern'>
            <Navbar />
            {/* Banner Area Start */}
            <div className="about-banner text-center">
                <div className="container">
                    <h1 className="fw-bold display-5">Contact Page</h1>
                    <div className="d-flex justify-content-center">
                        <a className="pe-1" href="/">Home</a>
                        <p>| Contact</p>
                    </div>
                </div>
            </div>
            {/* Banner Area End */}

            {/* Contact Form and Map Start */}
            <div className="contact-bg py-5">
                <div className="container py-5">
                    <div className="row py-5">
                        <div className="col-lg-6">
                            <form className="contact-form" id="reused_form" action="https://template.abusayedshuvo.com/roadrunner/POST">
                                <div className="form-floating mt-4">
                                    <input name="name" id="name" type="text" className="form-control" placeholder="Your Name*" required />
                                    <label htmlFor="name">Your Name</label>
                                </div>
                                <div className="form-floating mt-4">
                                    <input name="email" id="email" type="email" className="form-control" placeholder="Your Email*" required />
                                    <label htmlFor="email">Email address</label>
                                </div>
                                <div className="form-floating mt-4">
                                    <textarea name="comments" id="comments" className="form-control" style={{ height: '150px' }} placeholder="Your message..."></textarea>
                                    <label htmlFor="comments">Your message</label>
                                </div>
                                <div className="mt-5">
                                    <button type="submit" className="btn btn-primary px-5 py-3">Send message</button>
                                </div>
                            </form>
                            <div className="pt-5 mt-5" id="success_message" style={{ display: 'none' }}>
                                <h3 className="text-success">Your message was submitted successfully!</h3>
                            </div>
                            <div id="error_message" style={{ display: 'none' }}>
                                <h3 className="text-danger">Error</h3>
                                Sorry, there was an error sending your form.
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60898968.314048156!2d54.485937764410025!3d21.311883781410888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3663f18a24cbe857%3A0xa9416bfcd3a0f459!2sAsia!5e0!3m2!1sen!2sbd!4v1701872204769!5m2!1sen!2sbd"
                                width="600"
                                height="450"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                title="Contact Location"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            {/* Contact Form and Map End */}


            {/* Contact Info Area Start */}
            <div className="container py-5 my-5">
                <div className="contact-area mx-0 mx-lg-5">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 mb-4">
                            <div className="d-flex contact mb-2 mb-sm-50">
                                <i className="fas fa-phone-volume"></i>
                                <div className="ps-3">
                                    <p className="mb-0 fw-bold h5">Call us</p>
                                    <p className="con-num">+458-2563-5896</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4">
                            <div className="d-flex contact">
                                <i className="fas fa-envelope-open-text"></i>
                                <div className="ps-3">
                                    <p className="mb-2 fw-bold h5">Contact our team</p>
                                    <p className="con-num">yourmail@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex contact">
                                <i className="fas fa-location-dot"></i>
                                <div className="ps-3">
                                    <p className="mb-2 fw-bold h5">Location</p>
                                    <p className="con-num">South Asia 25368</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Contact Info Area End */}

           <FooterArea />
        </div>
    );
};

export default ContactPage;
