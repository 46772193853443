import React from 'react';
import './FooterArea.css'; // Import the CSS file for styling

const FooterArea = () => {
  return (
      <section className='FooterArea'>
        <div className="footer-bg">
          <div className="container" id="news">
            <div className="row">
              <div className="col-lg-4">
                <p className="h1 fw-bold">SaleeTours</p>
                <p className="pt-3">
                  At Salee Tours Vehicle Rentals, we're dedicated to providing you with
                  the ultimate journey on wheels. Whether you're exploring the open
                  road or navigating city streets.
                </p>
                <div className="footer-icon py-4">
                  <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook-f"></i></a>
                  <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin"></i></a>
                  <a href="https://www.behance.net" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-behance"></i></a>
                  <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-youtube"></i></a>
                </div>
              </div>
              <div className="col-lg-4 ps-2 ps-lg-5">
                <div className="footer-menu ps-0 ps-ls-5">
                  <h4 className="pt-3 pb-4">Our Services</h4>
                  <ul className="list-unstyled">
                    <li className="pb-3">
                      <i className="fa-solid fa-caret-right pe-2"></i>
                      <a href="/">Home</a>
                    </li>
                    <li className="pb-3">
                      <i className="fa-solid fa-caret-right pe-2"></i>
                      <a href="/vehicles">Vehicles</a>
                    </li>
                    <li className="pb-3">
                      <i className="fa-solid fa-caret-right pe-2"></i>
                      <a href="/contact">Contact</a>
                    </li>
                    <li className="pb-3">
                      <i className="fa-solid fa-caret-right pe-2"></i>
                      <a href="/about">About</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <h4 className="fw-bold pt-3 pb-4">Subscribe</h4>
                <p>
                  Interested in receiving updates about our services? Simply
                  subscribe and we'll keep you informed via email.
                </p>
                <div className="form-bg2 mb-5">
                  <div className="input-group mb-3 mt-3 px-3">
                    <input
                      type="text"
                      className="form-control blog-page me-2"
                      placeholder="Search Here"
                      aria-label="Search Here"
                      aria-describedby="button-addon2"
                    />
                    <button
                      className="footer-page-btn"
                      type="button"
                      id="button-addon2"
                    >
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <p className="text-center footer mb-0 pt-4">
              ©FutureX Copyrights 2024. All Rights Reserved.
            </p>
          </div>
        </div>
      </section>
  );
};

export default FooterArea;
