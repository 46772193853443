// src/pages/VehiclesPage.js

import React from 'react';
import Navbar from '../components/Navbar';
import VehicelFeatures from '../components/VehicelFeaturesSearch';
import FooterArea from '../components/FooterArea';

const VehiclesPage = () => {
  return (
    <div>
      <Navbar />
      <div class="about-banner">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 text-center">
              <h1 class="fw-bold display-5">Vehicles Rent</h1>
              <div class="d-flex justify-content-center">
                <a class="pe-1" href="#">Home </a>
                <p>| Vehicles</p>
              </div>
            </div>
          </div>
        </div>
    </div>
      <VehicelFeatures/>
      <FooterArea/>
    </div>
  );
}

export default VehiclesPage;
