// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import VehiclesPage from './pages/VehiclesPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import VehiclesDetails from './pages/VehiclesDetails';
import Vehicle from './pages/Vehicle';
import RentPage from './pages/RentPage';
import ThankYouPage from './pages/ThankYouPage'; // Import the Thank You page
import LoadingSpinner from './components/LoadingSpinner';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Change this duration as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <div>
        {loading && <LoadingSpinner />}
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/vehicles" element={<VehiclesPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/details/:model" element={<VehiclesDetails />} />
          <Route path="/vehicle" element={<Vehicle />} />
          <Route path="/rent" element={<RentPage />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          {/* <Route path="/rent" element={<NationalitySelection />} /> */}
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
