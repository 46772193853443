import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  InputAdornment,
  Paper,
  CircularProgress,
  Modal,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import IDIcon from '@mui/icons-material/AccountBox';
import DrivingExperienceIcon from '@mui/icons-material/DriveEta';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';

const Input = styled('input')({
  display: 'none',
});

const LocalTypeRentForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    idInfo: '',
    drivingLicense: null,
    utilityBill: null,
    governmentID: null,
    drivingExperience: '',
    contactNumber: '',
    driverOption: 'without driver', // Add driverOption to state
  });

  const [errors, setErrors] = useState({});
  const [previews, setPreviews] = useState({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear error on change
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
      const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!validTypes.includes(file.type)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Invalid file type. Please upload an image or PDF file.',
        }));
        setPreviews((prevPreviews) => ({
          ...prevPreviews,
          [name]: null,
        }));
        return;
      }

      if (file.size > maxSize) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'File size exceeds 5MB. Please upload a smaller file.',
        }));
        setPreviews((prevPreviews) => ({
          ...prevPreviews,
          [name]: null,
        }));
        return;
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));

      // Create a preview URL for the uploaded file
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviews((prevPreviews) => ({
          ...prevPreviews,
          [name]: e.target.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({}); // Clear previous errors

    // Check if all required fields are filled
    const requiredFields = [
      'name',
      'idInfo',
      'drivingLicense',
      'utilityBill',
      'governmentID',
      'drivingExperience',
      'contactNumber',
    ];

    for (const field of requiredFields) {
      if (!formData[field]) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `The ${field} field is required.`,
        }));
        return;
      }
    }

    setLoading(true);
    setSuccessMessage('');
    setOpenModal(true);

    try {
      const formDataToSend = new FormData();

      // Append text fields
      Object.keys(formData).forEach((key) => {
        formDataToSend.append(key, formData[key]);
      });

      const response = await fetch('https://saleetours.com/api/rents/local-rent', {
        method: 'POST',
        body: formDataToSend,
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Network response was not ok: ${errorMessage}`);
      }

      const result = await response.json();
      console.log(result); // Handle success response
      setSuccessMessage('Form submitted successfully!');
      
      // Navigate to the next route after a delay
      setTimeout(() => {
        navigate('/thank-you'); // Replace with your actual next route
      }, 2000);

    } catch (error) {
      console.error('Error uploading data:', error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        submit: 'An error occurred while submitting the form.',
      }));
    } finally {
      setLoading(false);
      setOpenModal(false);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxWidth: 500,
        margin: 'auto',
        mt: 5,
        borderRadius: 2,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
      }}
    >
      <TextField
        label="Name"
        variant="outlined"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        required
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon style={{ color: '#fff' }} />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: { color: '#fff' },
        }}
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          borderRadius: 1,
          input: { color: '#fff' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.5)',
            },
            '&:hover fieldset': {
              borderColor: '#fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#fff',
            },
          },
        }}
        error={!!errors.name}
        helperText={errors.name}
      />
      <TextField
        label="Contact Number"
        variant="outlined"
        name="contactNumber"
        value={formData.contactNumber}
        onChange={handleInputChange}
        required
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PhoneIcon style={{ color: '#fff' }} />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: { color: '#fff' },
        }}
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          borderRadius: 1,
          input: { color: '#fff' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.5)',
            },
            '&:hover fieldset': {
              borderColor: '#fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#fff',
            },
          },
        }}
        error={!!errors.contactNumber}
        helperText={errors.contactNumber}
      />
      <TextField
        label="ID Info"
        variant="outlined"
        name="idInfo"
        value={formData.idInfo}
        onChange={handleInputChange}
        required
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IDIcon style={{ color: '#fff' }} />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: { color: '#fff' },
        }}
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          borderRadius: 1,
          input: { color: '#fff' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.5)',
            },
            '&:hover fieldset': {
              borderColor: '#fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#fff',
            },
          },
        }}
        error={!!errors.idInfo}
        helperText={errors.idInfo}
      />
      <TextField
        label="Driving Experience (years)"
        variant="outlined"
        name="drivingExperience"
        type="number"
        value={formData.drivingExperience}
        onChange={handleInputChange}
        required
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DrivingExperienceIcon style={{ color: '#fff' }} />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: { color: '#fff' },
        }}
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          borderRadius: 1,
          input: { color: '#fff' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.5)',
            },
            '&:hover fieldset': {
              borderColor: '#fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#fff',
            },
          },
        }}
        error={!!errors.drivingExperience}
        helperText={errors.drivingExperience}
      />

      <FormControl
        variant="outlined"
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          borderRadius: 1,
          '& .MuiOutlinedInput-root': {
            input: { color: '#fff' },
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.5)',
            },
            '&:hover fieldset': {
              borderColor: '#fff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#fff',
            },
          },
        }}
        error={!!errors.driverOption}
      >
        <InputLabel
          id="driver-option-label"
          sx={{ color: '#fff' }}
        >
          Driver Option
        </InputLabel>
        <Select
          labelId="driver-option-label"
          name="driverOption"
          value={formData.driverOption}
          onChange={handleInputChange}
          label="Driver Option"
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: 1,
            '& .MuiOutlinedInput-root': {
              input: { color: '#fff' },
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
              },
              '&:hover fieldset': {
                borderColor: '#fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#fff',
              },
            },
          }}
        >
          <MenuItem value="without driver">Without Driver</MenuItem>
          <MenuItem value="with driver">With Driver</MenuItem>
        </Select>
        {errors.driverOption && (
          <Typography variant="body2" color="error">
            {errors.driverOption}
          </Typography>
        )}
      </FormControl>

      {['drivingLicense', 'utilityBill', 'governmentID'].map((fileField) => (
        <Box key={fileField}>
          <label htmlFor={`${fileField}-upload`}>
            <Input
              accept="image/*,application/pdf"
              id={`${fileField}-upload`}
              type="file"
              name={fileField}
              onChange={handleFileChange}
              required
            />
            <Button
              variant="contained"
              component="span"
              startIcon={<PhotoCamera />}
              sx={{ mt: 2, color: '#fff', backgroundColor: '#333' }}
            >
              Upload {fileField.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
            </Button>
          </label>
          {errors[fileField] && (
            <Typography variant="body2" color="error">
              {errors[fileField]}
            </Typography>
          )}
          {previews[fileField] && (
            <Box mt={2}>
              <Typography variant="body2">Preview:</Typography>
              <img src={previews[fileField]} alt={fileField} width="100%" />
            </Box>
          )}
        </Box>
      ))}

      <Button
        variant="contained"
        onClick={handleSubmit}
        sx={{ mt: 4, color: '#fff', backgroundColor: '#333' }}
      >
        Submit
      </Button>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Typography variant="h6" color="success">
              {successMessage || errors.submit}
            </Typography>
          )}
        </Box>
      </Modal>
    </Paper>
  );
};

export default LocalTypeRentForm;
